var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","width":"100%"}},[_c('br'),_c('v-container',{staticStyle:{"padding":"0"},attrs:{"fluid":""}},[_c('v-layout',{staticStyle:{"margin-top":"0%","background-size":"cover"},attrs:{"row":"","wrap":"","justify-center":"","align-center":""}},[_c('v-flex',{staticClass:"contact",style:(_vm.mdUp ? 'text-align: left;' : 'text-align: center'),attrs:{"xs12":"","sm6":""}},[_c('v-card',{class:_vm.$vuetify.breakpoint.mdAndUp ? '' : 'contact-mobile',staticStyle:{"background":"transparent"},attrs:{"elevation":"0"}},[_c('v-container',[_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('div',{class:_vm.$vuetify.breakpoint.smAndUp
                    ? 'contact_form_wrapper'
                    : 'contact_form_wrapper_mobile'},[_c('v-layout',{attrs:{"row":"","justify-center":""}},[(!_vm.mdup)?_c('h1',{style:(_vm.mdUp
                        ? 'color: #161615; font-size: 70px'
                        : 'color: #161615; font-size: 50px')},[_vm._v(" Contáctanos ")]):_c('h1',{style:(_vm.mdUp
                        ? 'color: #161615; font-size: 30px'
                        : 'color: #161615; font-size: 30px')},[_vm._v(" Contáctanos ")])]),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-text-field',{attrs:{"color":"#772336","height":"50","label":"Nombre y apellidos","placeholder":_vm.language == 0
                        ? 'Nombre y apellidos'
                        : 'Nombre y apellidos'},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-select',{attrs:{"height":"50","background-color":"white","item-value":"value","item-text":"text","items":_vm.sex_options,"placeholder":_vm.language == 0 ? 'Género' : 'Género'},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}})],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-text-field',{attrs:{"color":"#772336","height":"50","label":"Correo electrónico","placeholder":_vm.language == 0
                        ? 'Correo electrónico'
                        : 'Correo electrónico'},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-select',{attrs:{"height":"50","background-color":"white","items":_vm.estadosMX,"item-value":"nombre","item-text":"nombre","placeholder":_vm.language == 0
                        ? 'Estado de la república mexicana donde reside'
                        : 'Estado de la república mexicana donde reside'},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-text-field',{attrs:{"color":"#772336","height":"50","label":"Número de teléfono","placeholder":_vm.language == 0
                        ? 'Número de teléfono'
                        : 'Número de teléfono'},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-textarea',{staticStyle:{"resize":"none"},attrs:{"height":"150","no-resize":"","color":"#772336","label":"Comentarios","placeholder":_vm.language == 0 ? 'Comentarios' : 'Comentarios'},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-btn',{attrs:{"large":"","width":"300","rounded":"","dark":"","color":"#772336"},on:{"click":_vm.submitForm}},[_vm._v(" Enviar ")])],1)],1)])],1),_c('v-container',[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{staticClass:"submit_form",attrs:{"row":"","justify-center":""}})],1)],1)],1)],1),(_vm.mdUp)?_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('v-img',{attrs:{"height":"1000","src":"/static/DSC_0018.png"}})],1):_vm._e()],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('iframe',{staticStyle:{"border":"0"},attrs:{"src":("https://maps.google.com/maps?q=" + _vm.lat + "," + _vm.lon + "&hl=es&z=14&output=embed"),"width":"100%","height":"500px","frameborder":"0","allowfullscreen":"","aria-hidden":"false","tabindex":"0"}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }