<template>
  <v-card flat width="100%">
    <!-- PARALLAX AQUI -->
    <br />

    <v-container fluid style="padding: 0">
      <v-layout
        row
        wrap
        style="margin-top: 0%; background-size: cover"
        justify-center
        align-center
      >
        <v-flex
          xs12
          sm6
          class="contact"
          :style="mdUp ? 'text-align: left;' : 'text-align: center'"
        >
          <v-card
            :class="$vuetify.breakpoint.mdAndUp ? '' : 'contact-mobile'"
            style="background: transparent"
            elevation="0"
          >
            <v-container>
              <v-layout row justify-center>
                <div
                  :class="
                    $vuetify.breakpoint.smAndUp
                      ? 'contact_form_wrapper'
                      : 'contact_form_wrapper_mobile'
                  "
                >
                  <v-layout row justify-center>
                    <h1
                        v-if="!mdup"
                      :style="
                        mdUp
                          ? 'color: #161615; font-size: 70px'
                          : 'color: #161615; font-size: 50px'
                      "
                    >
                      Contáctanos
                    </h1>
                    <h1
                    v-else
                      :style="
                        mdUp
                          ? 'color: #161615; font-size: 30px'
                          : 'color: #161615; font-size: 30px'
                      "
                    >
                      Contáctanos
                    </h1>
                  </v-layout>
                  <v-layout row justify-center>
                    <v-text-field
                      color="#772336"
                      height="50"
                      v-model="name"
                      label="Nombre y apellidos"
                      :placeholder="
                        language == 0
                          ? 'Nombre y apellidos'
                          : 'Nombre y apellidos'
                      "
                    ></v-text-field>
                  </v-layout>
                  <v-layout row justify-center>
                    <v-select
                      v-model="gender"
                      
                      height="50"
                      background-color="white"
                      item-value="value"
                      item-text="text"
                      :items="sex_options"
                      :placeholder="language == 0 ? 'Género' : 'Género'"
                    ></v-select>
                  </v-layout>

                  <v-layout row justify-center>
                    <v-text-field
                      color="#772336"
                      height="50"
                      v-model="email"
                      label="Correo electrónico"
                      :placeholder="
                        language == 0
                          ? 'Correo electrónico'
                          : 'Correo electrónico'
                      "
                    ></v-text-field>
                  </v-layout>

                  <v-layout row justify-center>
                    <v-select
                      v-model="state"
                      height="50"
                      background-color="white"
                      :items="estadosMX"
                      item-value="nombre"
                      item-text="nombre"
                      :placeholder="
                        language == 0
                          ? 'Estado de la república mexicana donde reside'
                          : 'Estado de la república mexicana donde reside'
                      "
                    ></v-select>
                  </v-layout>

                  <v-layout row justify-center>
                    <v-text-field
                      color="#772336"
                      height="50"
                      v-model="phone"
                      label="Número de teléfono"
                      :placeholder="
                        language == 0
                          ? 'Número de teléfono'
                          : 'Número de teléfono'
                      "
                    ></v-text-field>
                  </v-layout>

                  <v-layout row justify-center>
                    <v-textarea
                      height="150"
                      no-resize
                      color="#772336"
                      style="resize: none;"
                      v-model="comment"
                      label="Comentarios"
                      :placeholder="
                        language == 0 ? 'Comentarios' : 'Comentarios'
                      "
                    ></v-textarea>
                  </v-layout>
                  <v-layout row justify-center>
                    <v-btn
                      large
                      width="300"
                      rounded
                      dark
                      color="#772336"
                      @click="submitForm"
                    >
                      Enviar
                    </v-btn>
                  </v-layout>
                </div>
              </v-layout>
            </v-container>
            <v-container>
              <v-flex xs12>
                <v-layout row justify-center class="submit_form"> </v-layout>
              </v-flex>
            </v-container>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 v-if="mdUp">
          <v-img height="1000" src="/static/DSC_0018.png"></v-img>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container fluid>
                  <iframe
            :src="`https://maps.google.com/maps?q=${lat},${lon}&hl=es&z=14&amp;output=embed`"
            width="100%"
            height="500px"
            frameborder="0"
            style="border: 0"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
    </v-container>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    name: "",
    gender: "",
    email: "",
    state: "",
    phone: "",
    comment: "",
    sex_options: [
      {
        text: "Hombre",
        value: "male",
      },
      {
        text: "Mujer",
        value: "female",
      },
      {
        text: "Otro",
        value: "other",
      },
    ],
    estadosMX: [
      { clave: "AGS", nombre: "Aguascalientes" },
      { clave: "BC", nombre: "Baja California" },
      { clave: "BCS", nombre: "Baja California Sur" },
      { clave: "CHI", nombre: "Chihuahua" },
      { clave: "CHS", nombre: "Chiapas" },
      { clave: "CMP", nombre: "Campeche" },
      { clave: "CMX", nombre: "Ciudad de México" },
      { clave: "COA", nombre: "Coahuila" },
      { clave: "COL", nombre: "Colima" },
      { clave: "DGO", nombre: "Durango" },
      { clave: "GRO", nombre: "Guerrero" },
      { clave: "GTO", nombre: "Guanajuato" },
      { clave: "HGO", nombre: "Hidalgo" },
      { clave: "JAL", nombre: "Jalisco" },
      { clave: "MCH", nombre: "Michoacán" },
      { clave: "MEX", nombre: "Estado de México" },
      { clave: "MOR", nombre: "Morelos" },
      { clave: "NAY", nombre: "Nayarit" },
      { clave: "NL", nombre: "Nuevo León" },
      { clave: "OAX", nombre: "Oaxaca" },
      { clave: "PUE", nombre: "Puebla" },
      { clave: "QR", nombre: "Quintana Roo" },
      { clave: "QRO", nombre: "Querétaro" },
      { clave: "SIN", nombre: "Sinaloa" },
      { clave: "SLP", nombre: "San Luis Potosí" },
      { clave: "SON", nombre: "Sonora" },
      { clave: "TAB", nombre: "Tabasco" },
      { clave: "TLX", nombre: "Tlaxcala" },
      { clave: "TMS", nombre: "Tamaulipas" },
      { clave: "VER", nombre: "Veracruz" },
      { clave: "YUC", nombre: "Yucatán" },
      { clave: "ZAC", nombre: "Zacatecas" },
    ],
    lon: -100.277624,
    lat: 25.621344,
    faqs: [
      {
        title: "¿Como se clasifican los residuos?",
        text:
          "En México de acuerdo a nuestra legislación ambiental se clasifican en Residuos de Manejo Especial y Residuos Peligrosos",
      },
      {
        title: "¿Qué son los Residuos de Manejo Especial?",
        text:
          "Son aquellos desechos que no contienen características de peligrosidad y que pueden ser susceptibles a valoración para reciclaje.",
      },
      {
        title: "¿Qué son los Residuos Peligrosos?",
        text:
          "Son aquellos desechos que no contienen características de peligrosidad y que pueden ser susceptibles a valoración para reciclaje.",
      },
      {
        title: "¿Que significa CRETIB?",
        text:
          "Son características de peligrosidad por sus siglas Corrosivo, Reactivo, Explosivo, Toxico, Inflamable, Bio Infeccioso",
      },
      {
        title: "¿Qué es un Manifiesto?",
        text:
          "Es un documento en el cual se registran las actividades de manejo de residuos ya sea de Manejo Especial o de Residuos Peligrosos",
      },
      {
        title: "¿Qué es un Generador de Residuos?",
        text:
          "Se refiere a cualquier persona física o moral que en su actividad genere desechos ya sean de Manejo Especial o Peligrosos",
      },
      {
        title: "¿Qué es un prestador de servicios de residuos?",
        text:
          "Se define a una persona física o moral la cual está Autorizada por las dependencias que regulan las actividades de las diferentes etapas del manejo de Residuos de Manejo Especial o Peligrosos",
      },
      {
        title: "¿Qué es una Autorización para manejo de Residuos?",
        text:
          "Es un documento Legal expedido por una Dependencia de Gobierno la cual regula las actividades del manejo de Residuos",
      },
    ],
    headerEsp: "",
    headerEng: "",
    formDesc: "",
    formDescEng: "",
    dialog: false,
    dialog2: false,
    myerror: "",
    orderList: [
      "Solicitud de empleo",
      "Solicitud de proveedor",
      "Información de nuestros servicios",
      "Otro",
    ],
    OrderListEng: [
      "Job application",
      "Vendor request",
      "Information about our services",
      "Other",
    ],
    name: "",
    email: "",
    phone: "",
    message: "",
    motive: "",
    nameTitle: "Nombre*",
    mailTitle: "Correo Electrónico*",
    phoneTitle: "Teléfono*",
    companyTitle: "Empresa*",
    messageTitle: "Mensaje*",
    bannerimg: "/static/DSC_0032.png",
  }),
  methods: {
submitForm() {
      let that = this
      var axios = require("axios");
      var qs = require("qs");
      var data = qs.stringify({
        name: this.name,
        gender: this.gender,
        email: this.email,
        state: this.state,
        phone: this.phone,
        comment: this.comment,
        type: "contact",
      });
      var config = {
        method: "post",
        url: "https://api.grupostc.com.mx/api/v1/serviceContacts",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          that.name = ""
          that.gender = ""
          that.email = ""
          that.state = ""
          that.phone = ""
          that.comment = ""

          that.$store.commit("toggle_alert", {
            color: "green",
            text: response.data.message,
          });

        })
        .catch(function (error) {
          console.log(error);
            that.$store.commit("toggle_alert", {
            color: "red",
            text: error.response.data.message,
          });
        });
    },
  },
  mounted() {
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
    mdUp() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
};
</script>
<style scoped>
.label_contact_input {
  font-size: 12px;
  color: #cfcfcf;
}
.contact_form_container {
  margin-top: 20px;
}
.submit_form {
  margin-top: 50px;
}
.border_coverage {
  margin-left: -2px;
}
.input_holder {
  width: 100%;
  margin-bottom: 20px;
  height: 40px;
  border-left-style: solid;
  border-left-width: 5px;
  border-left-color: #26e07c;
  /*   border-top-style: solid;
  border-top-width: .2px;
  border-top-color: #CFCFCF;
  border-right-style: solid;
  border-right-width: .2px;
  border-right-color: #CFCFCF;
  border-right-style: solid;
  border-bottom-width: .2px;
  border-bottom-color: #CFCFCF; 
  border-bottom: 20%;*/
}
.input_holder_area {
  padding-left: -10px;
  width: 100%;
  margin-bottom: 20px;
  height: 117px;
  border-left-style: solid;
  border-left-width: 5px;
  border-left-color: #26e07c;
  /*   border-top-style: solid;
  border-top-width: .2px;
  border-top-color: #CFCFCF;
  border-right-style: solid;
  border-right-width: .2px;
  border-right-color: #CFCFCF;
  border-right-style: solid;
  border-bottom-width: .2px;
  border-bottom-color: #CFCFCF;
  border-bottom: 20%; */
}
.contact_form_wrapper {
  max-width: 70%;
  width: 10000px;
}
.contact_form_wrapper_mobile {
  max-width: 90%;
}
.text_title {
  font-weight: 700;
  font-family: "Open sans", sans-serif;
  font-size: 10px;
  color: #091f2c;
  text-align: left;
}
.contact_text_form {
  font-size: 20px;
  color: white;
  margin: auto;
  font-weight: 0;
}

.mobile-title {
  margin: 0 auto;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 600;
  font-size: 8vh;
}
.contact-mobile {
  max-width: 80%;
}

@media (max-width: 900px) and (min-width: 0px) {
  .mobile-title {
    margin: 0 auto;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 600;
    font-size: 5vh;
  }
}
</style>